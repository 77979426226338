export const environment = {
  production: true,
  wsEndpoint: "wss://1hr1ljk1o1.execute-api.us-west-2.amazonaws.com/staging",
  awsAPIURL: 'https://k0zmbfs553.execute-api.us-west-2.amazonaws.com/staging/bay-manager-explorer',
  apiServer: 'https://stglauncher.fullswingapps.com/api/fs',
  hostName: 'stgbaymanager.fullswingapps.com',
  appConfigPath: './assets/config/app-config.json',
  defaultPage: 'event',

  //Local strorage values
  navName: '_fs_nav_name',
  bayManagerUser: 'Bay-Manager-User',
  calendarState: '_fs_fc_state',

  langId: '_fs_lang_id',
  defaultLanguage: 'en',
  defaultFontFamily: 'DavisSans',

  breakPointScreen: {
    small: 576,
    Medium: 768,
    large: 992,
    extraLarge: 1200,
    xExtraLarge: 1400,
  },
}
